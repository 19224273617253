<template>
  <AwlLoader class="awl-loader-bouncing" v-bind="{ ...$attrs, ...$props }"><slot v-if="$scopedSlots.default || text">{{ text }}</slot></AwlLoader>
</template>

<script>

import awlLoaderPropsMixin from '../mixins/awlLoaderProps.js';

import AwlLoader from './AwlLoader.vue';

export default {
  mixins: [awlLoaderPropsMixin],
  components: {
    AwlLoader,
  },
};

</script>

<style lang="scss">

.awl-loader-bouncing {
  img {
    &.animated {
      animation-name: bounce;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      transition: all 1s ease-in-out;
      transform-origin: center bottom;
    }

    @keyframes bounce {
      from,
      20%,
      53%,
      80%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
      }

      40%,
      43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0);
      }

      70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
      }

      90% {
        transform: translate3d(0, -4px, 0);
      }
    }
  }
}

</style>
