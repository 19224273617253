import sidebarMixin from './sidebar.js';

let body;

export default {
  mixins: [sidebarMixin],
  methods: {
    doOpenSidebar () {
      body.classList.add('show-mobile-menu');
    },
    doCloseSidebar () {
      body.classList.remove('show-mobile-menu');
    },
  },
  watch: {
    $open (oldValue, newValue) {
      if (!newValue) {
        this.doOpenSidebar();

        return;
      }

      this.doCloseSidebar();
    },
  },
  mounted () {
    body = document.getElementsByTagName('body')[0];
  },
};
