import Vue from 'vue';

export default Vue.observable({
  closed: true,
  toggle () {
    this.closed = !this.closed;
  },
  open () {
    this.closed = false;
  },
  close () {
    this.closed = true;
  },
});
