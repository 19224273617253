import $api, { addEndpoint as $apiAddEndpoint } from '../../Api/services/api.js';
import $axios from './axios.js';

export const init = () => {
  $apiAddEndpoint('createToken', options => $axios.getOAuth2TokenInstance().post('/token', options));
  $apiAddEndpoint('getMe', () => $axios.get('/me'));
  $apiAddEndpoint('getLoggedMe', () => this.getMe().then(res => res.data).catch(() => false));
};

export default $api;
