import Vue from 'vue';

export default Vue.observable({
  closed: true,
  question: null,
  ok: null,
  cancel: null,
  accepted: false,
  open (question, okLabel, cancelLabel) {
    this.question = question;
    this.ok = okLabel;
    this.cancel = cancelLabel;
    this.closed = false;
    this.accepted = false;
  },
  close () {
    this.closed = true;
    this.question = null;
    this.ok = null;
    this.cancel = null;
  },
  accept () {
    this.accepted = true;
    this.close();
  },
  reject () {
    this.accepted = false;
    this.close();
  },
});
