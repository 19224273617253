import $oauth2 from '@/libs/OAuth2/services/auth.js';
import checkGuards from '@/security/checkGuards.js';

export default async (to, from, next) => {
  await $oauth2.getMe();

  if (checkGuards(to, $oauth2, next)) {
    return;
  }

  next();
};
