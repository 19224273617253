import $api, { init as $apiInit } from '../services/api.js';

export default (Vue, options) => {
  Vue.api = Vue.observable($api);
  $apiInit((options || {}).endpoints || {});
  Object.defineProperties(Vue.prototype, {
    $api: {
      get: () => Vue.api,
    },
  });
};
