const api = {};

const cachedData = {};

export const init = (apidef = {}) => {
  for (const name in apidef) {
    addEndpoint(name, apidef[name]);
  }
};

export const addEndpoint = (name, endpoint) => {
  api[name] = endpoint;
};

export const cacheCall = (name, promise) => {
  if (cachedData[name]) {
    return Promise.resolve({
      data: cachedData[name],
    });
  }

  return new Promise((resolve, reject) => {
    promise()
      .then(res => {
        cachedData[name] = res?.data || {};

        resolve(res);
      })
      .catch(reject)
    ;
  });
};

export default api;
