const isDev = process.env.NODE_ENV === 'development';

export default {
  // eslint-disable-next-line
  debug: (...params) => isDev ? console.log(...params) : null,

  // eslint-disable-next-line
  warn: (...params) => console.warn(...params),

  // eslint-disable-next-line
  error: (...params) => console.error(...params),
};
