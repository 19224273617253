export default {
  computed: {
    isLogged () {
      return !!this?.$oauth2?.me;
    },
    isAnonymous () {
      return !this.isLogged;
    },
    role () {
      return this?.$oauth2?.me?.role;
    },
  },
  methods: {
    isRole (role) {
      return role === this.role;
    },
  },
};
