<template>
  <div>
    <slot :opened="opened" :question="question" :okLabel="okLabel" :cancelLabel="cancelLabel" :reject="reject" :accept="accept"></slot>
  </div>
</template>

<script>

import confirmService from '../services/confirmService.js';

export default {
  computed: {
    opened () {
      return !confirmService.closed;
    },
    question () {
      return confirmService.question;
    },
    okLabel () {
      return confirmService.ok;
    },
    cancelLabel () {
      return confirmService.cancel;
    },
  },
  methods: {
    reject () {
      confirmService.reject();
    },
    accept () {
      confirmService.accept();
    },
  },
};

</script>

<style lang="scss"></style>
