import rolesWithOneRolePerUserMixin from '../libs/OAuth2/mixins/rolesWithOneRolePerUser';

export default {
  mixins: [rolesWithOneRolePerUserMixin],
  computed: {
    isAdmin () {
      return this.isRole('admin');
    },
    isOperator () {
      return this.isRole('operator');
    },
  },
};
