import $localStorage from '../../Storage/services/localStorage.js';
import $sessionStorage from '../../Storage/services/sessionStorage.js';
import $axios from './axios.js';
import $api from './api.js';

const OAuth2 = {
  initialized: false,
  providers: {},
  config: {
    enableLoginWithCredentials: true,
    tokenKey: 'oauth2-token',
    refreshKey: 'oauth2-refresh',
    useSessionStorage: false,
    tokenEndpoint: '/token',
    client_id: null,
    client_secret: null,
  },
  configProviders: {},
  accessToken: null,
  refreshToken: null,
  refreshingToken: {
    active: false,
    subscribers: [],
    promise: null,
  },
  onLoggedInCallbacks: [],
  onLoggedOutCallbacks: [],
  me: null,
};

// Config
OAuth2.init = (config) => {
  OAuth2.initialized = true;
  OAuth2.setConfig(config);
};

OAuth2.setConfig = (config) => {
  OAuth2.config = Object.assign({}, OAuth2.config, config, {
    useSessionStorage: !!+$localStorage.get('useSessionStorage'),
  });
};

// Tokens
OAuth2.getStorage = () => OAuth2.config.useSessionStorage ? $sessionStorage : $localStorage;

OAuth2.tokens = {
  access: {
    get: () => {
      if (OAuth2.accessToken === null) {
        OAuth2.accessToken = OAuth2.getStorage().get(OAuth2.config.tokenKey);
      }

      return OAuth2.accessToken;
    },
    set: (token) => {
      OAuth2.getStorage().put(OAuth2.config.tokenKey, token);
      OAuth2.accessToken = token;
    },
    remove: () => {
      OAuth2.getStorage().delete(OAuth2.config.tokenKey);
      OAuth2.accessToken = null;
    },
  },
  refresh: {
    get: () => {
      if (OAuth2.refreshToken === null) {
        OAuth2.refreshToken = OAuth2.getStorage().get(OAuth2.config.refreshKey);
      }

      return OAuth2.refreshToken;
    },
    set: (token) => {
      OAuth2.getStorage().put(OAuth2.config.refreshKey, token);
      OAuth2.refreshToken = token;
    },
    remove: () => {
      OAuth2.getStorage().delete(OAuth2.config.refreshKey);
      OAuth2.refreshToken = null;
    },
  },
};
OAuth2.tokens.reset = () => {
  OAuth2.tokens.access.remove();
  OAuth2.tokens.refresh.remove();
};

// Http Requests
OAuth2.getAxiosInstance = () => $axios.getOAuth2TokenInstance();
OAuth2.requestToken = options => $api.createToken(Object.assign({}, {
  client_id: OAuth2.config.client_id,
  client_secret: OAuth2.config.client_secret,
}, options));

// Credentials
OAuth2.loginRequest = (options, forceLogout = false) => {
  const requestOptions = Object.assign({}, options);
  delete requestOptions.onLoggedIn;

  return OAuth2
    .requestToken(requestOptions)
    .then(
      async response => {
        OAuth2.tokens.access.set(response.data.access_token);
        OAuth2.tokens.refresh.set(response.data.refresh_token);

        const me = await OAuth2.getMe();

        return me;
      },
      err => {
        const error = new Error('Failed login');
        error.response = err.response;
        throw error;
      },
    )
    .then(me => {
      if (!me) {
        throw new Error('Failed login');
      }

      if (options.onLoggedIn && typeof options.onLoggedIn === 'function') {
        options.onLoggedIn();
      } else if (!options.onLoggedIn) {
        OAuth2.onLoggedIn(me);
      }

      return me;
    })
    .catch((err) => {
      if (forceLogout) {
        return OAuth2.logout()
          .then(() => {
            throw err;
          })
        ;
      }

      throw err;
    })
  ;
};
OAuth2.loginWithCredentials = (username, password) => new Promise((resolve, reject) => {
  if (!OAuth2.config.enableLoginWithCredentials) {
    this.$log.warn('[Auth] Login with credentials is disabled');
    throw Error('Login with credentials is disabled');
  }

  OAuth2
    .loginRequest({
      grant_type: 'password',
      username,
      password,
    })
    .then(r => resolve(r))
    .catch(e => reject(e))
  ;
});
OAuth2.logout = () => new Promise(resolve => {
  OAuth2.tokens.reset();
  OAuth2.me = null;
  OAuth2.onLoggedOut();

  resolve();
});
OAuth2.setLoginCallback = callback => {
  OAuth2.onLoggedInCallbacks = [callback];
};
OAuth2.addLoginCallback = callback => OAuth2.onLoggedInCallbacks.push(callback);
OAuth2.setLogoutCallback = callback => {
  OAuth2.onLoggedOutCallbacks = [callback];
};
OAuth2.addLogoutCallback = callback => OAuth2.onLoggedOutCallbacks.push(callback);
OAuth2._getMePromise = null;
OAuth2._getMePromiseForced = false;
OAuth2.getMe = forced => {
  if (forced && !OAuth2._getMePromiseForced) {
    OAuth2._getMePromiseForced = true;
    OAuth2._getMePromise = null;
  }

  if (OAuth2._getMePromise === null) {
    OAuth2._getMePromise = new Promise(resolve => {
      if (!OAuth2._getMePromiseForced && OAuth2.me) {
        resolve(OAuth2.me);

        return;
      }

      if (!OAuth2.tokens.access.get()) {
        resolve(null);

        return;
      }

      $api.getMe()
        .then(res => res.data)
        .then(me => {
          OAuth2.me = me;

          resolve(OAuth2.me);
        })
        .catch(() => {
          OAuth2.me = null;

          OAuth2
            .logout()
            .finally(() => resolve(OAuth2.me))
          ;
        })
        .finally(() => {
          OAuth2._getMePromiseForced = false;
        })
      ;
    }).finally(() => {
      OAuth2._getMePromise = null;
    });
  }

  return OAuth2._getMePromise;
};
OAuth2.refreshMe = () => OAuth2.getMe(true);
OAuth2.onLoggedIn = me => OAuth2.onLoggedInCallbacks.forEach(cb => cb(me));
OAuth2.onLoggedOut = () => OAuth2.onLoggedOutCallbacks.forEach(cb => cb());

// Refresh Token
OAuth2.refreshingToken.subscribe = (callback, callbackError) => OAuth2.refreshingToken.subscribers.push({
  resolve: callback,
  error: callbackError,
});
OAuth2.refreshingToken.success = (token, refresh) => new Promise(resolve => {
  OAuth2.tokens.access.set(token);
  OAuth2.tokens.refresh.set(refresh);

  OAuth2.refreshingToken.subscribers.map(subscriber => subscriber.resolve ? subscriber.resolve(token) : null);
  OAuth2.refreshingToken.subscribers = [];

  resolve();
});
OAuth2.refreshingToken.denied = () => OAuth2.logout()
  .finally(() => {
    OAuth2.refreshingToken.subscribers.map(subscriber => subscriber.error ? subscriber.error() : null);
    OAuth2.refreshingToken.subscribers = [];
  })
;
OAuth2.refresh = () => {
  if (OAuth2.refreshingToken.promise === null) {
    OAuth2.refreshingToken.promise = OAuth2.requestToken({
      grant_type: 'refresh_token',
      refresh_token: OAuth2.tokens.refresh.get(),
    })
      .then(response => [response.data.access_token, response.data.refresh_token])
      .then(([AccessToken, RefreshToken]) => OAuth2.refreshingToken.success(AccessToken, RefreshToken))
      .catch(() => OAuth2.refreshingToken.denied())
      .finally(() => {
        OAuth2.refreshingToken.promise = null;
      })
    ;
  }

  return OAuth2.refreshingToken.promise;
};

// Providers
OAuth2.addProvider = (provider, config) => {
  const name = provider.name || 'default';

  if (OAuth2.hasProvider(name)) {
    return;
  }

  provider.config = Object.assign({}, OAuth2.config, config || {});
  OAuth2.providers[name] = provider;
};
OAuth2.hasProvider = providerName => !!OAuth2.providers[providerName];
OAuth2.getProvider = providerName => new Promise((resolve, reject) => {
  if (!OAuth2.hasProvider(providerName)) {
    reject(new Error('Can\'t find provider "' + providerName + '"'));

    return;
  }

  const provider = OAuth2.providers[providerName];

  if (!provider.init || typeof provider.init !== 'function') {
    reject(new Error('Provider "' + providerName + '" is not valid.'));

    return;
  }

  if (!provider.initialized) {
    provider
      .init(provider.config)
      .then(() => {
        provider.initialized = true;

        resolve(provider);
      })
      .catch(() => reject(new Error('Can\'t init provider "' + providerName + '"')))
    ;

    return;
  }

  resolve(provider);
});
OAuth2.loginWithProvider = providerName => new Promise((resolve, reject) => {
  OAuth2
    .getProvider(providerName)
    .then(provider => {
      if (!provider.getAuthorizationCode || typeof provider.getAuthorizationCode !== 'function') {
        throw Error('Failed login');
      }

      return provider.getAuthorizationCode();
    })
    .then(ac => {
      OAuth2
        .loginRequest({
          grant_type: providerName,
          code: ac,
        })
        .then(
          r => resolve(r),
          e => reject(e),
        )
      ;
    })
    .catch(() => reject(Error('Failed login')))
  ;
});

export default OAuth2;
