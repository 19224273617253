<template>
  <div class="sidebar-hamburger" :class="{ open: $open }" @click.prevent="toggleSidebar">
    <slot></slot>
  </div>
</template>

<script>

import sidebarControlMixin from '../../mixins/sidebarControl.js';

export default {
  mixins: [sidebarControlMixin],
};

</script>

<style lang="scss">

.sidebar-hamburger {
  cursor: pointer;
}

</style>
