export default {
  props: {
    centerContent: {
      type: Boolean,
      default: true,
    },
    fitViewport: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '120px',
    },
    text: {
      type: String,
      default: null,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    imagePath: {
      type: String,
      default: null,
    },
  },
};
