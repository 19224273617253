const _checkGuardsOnLevel = (to, $oauth2, next, checkGuardsRules) => {
  for (let i = 0, length = checkGuardsRules.length; i < length; i++) {
    if (checkGuardsRules[i](to, $oauth2, next)) {
      return true;
    }
  }

  return false;
};

export const checkGuards = (to, $oauth2, next, checkGuardsRules = []) => {
  if (!Array.isArray(to?.matched) || to.matched.length === 0) {
    return false;
  }

  if (checkGuardsRules.length === 0) {
    return false;
  }

  for (let i = 0, length = to.matched.length; i < length; i++) {
    if (_checkGuardsOnLevel(to.matched[i], $oauth2, next, checkGuardsRules)) {
      return true;
    }
  }

  return false;
};
